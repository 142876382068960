const polyfillNodeListForEach = function (): void {
  if (!NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback: any, thisArg: any): void {
      thisArg = thisArg || window;
      for (let i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }
};

export default polyfillNodeListForEach;
