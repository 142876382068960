import { polyfillPromise } from '@ambita/ambita-components-core';
import ConfigDataService from '../dataServices/config';
import polyfillClosest from '../polyfills/closest';
import polyfillIsFinite from '../polyfills/isFinite';
import polyfillNodeListForEach from '../polyfills/nodelistForEach';

const loadAppCode = function (): PromiseLike<any> {
  return new Promise((resolve) => {
    require.ensure(
      ['../app'],
      (require: any): void => {
        const app = require('../app');
        resolve(app);
      },
      null,
      'app'
    );
  });
};

export const bootstrap = async function (): Promise<void> {
  polyfillPromise();
  polyfillClosest();
  polyfillNodeListForEach();
  polyfillIsFinite();

  await ConfigDataService.fetchConfig();
  const app = await loadAppCode();
  app.initialize();
};
